import React, { useEffect } from 'react';
import Meuatendimento from '../services/meuatendimento'
import Contato from '../contato'
import ExpandableComponent from '../expandable'
import AtendimentoInfo from '../services/valores'
import PaymentOptions from '../services/formas_de_pagamento'


const ServicoNatu = () => {

  return (
    <div className="max-w-3xl m-auto min-h-screen py-16">
      <h1 className='text-3xl text-center md:text-4xl'>Tratamento com Ayurveda</h1>
      <div className='p-4'>

        <p className='mb-4'>
          Ayurveda é uma ciência, uma filosofia, um sistema tradicional de conhecimento, uma arte que começou a se desenvolver na índia há cerca de 5 mil anos. Esta antiga prática é um componente integral da sabedoria contida nos Vedas, os textos sagrados que constituem a base da cultura indiana.
        </p>
        <p className='mb-4'>
          A palavra Ayurveda vem do sânscrito, onde  Ayur/Ayus significa ‘’vida’’  e Veda significa ‘’conhecimento/ciência’’, conhecido popularmente como ‘’A ciência da vida’’.
        </p>
        <p className='mb-4'>
          Juntas iremos olhar para você como um ser multidimensional, analisando todos os aspectos que o compõem: físico, mental, metabólico e vital, sempre procurando estabelecer uma harmonia com o todo com o olhar do Ayurveda. O Ayurveda é baseado em uma compreensão da natureza e do universo e considera que o corpo humano é uma parte integrante deste todo. E tudo e todos é composto pelos 5 elementos (Éter, Ar, Fogo, Água e Terra).
        </p>
        <p className='mb-4'>
          Minha missão e meta é te auxiliar ao máximo, a entender seus padrões de desequilíbrio e fazer o melhor que posso em sua vida, te dando caminhos e opções para lidar com suas questões de uma forma autônoma, consciente e natural, seja qual for o seu objetivo. Sempre trabalharemos olhando para seu estilo de vida, hábitos alimentares, sob o Ayurveda.
        </p>
        <p className="mb-4">
          O Ayurveda adota uma abordagem individualizada, que meticulosamente considera as singularidades de cada indivíduo, incluindo sua idade, o estado atual de saúde e o estilo de vida. As sugestões terapêuticas são flexíveis, permitindo que sejam incorporadas de forma gradual e harmoniosa na rotina diária, sempre respeitando as capacidades e necessidades individuais. Por essa razão, a Ayurveda é uma terapia acessível e inclusiva, que pode ser praticada por qualquer pessoa, sem qualquer discriminação em relação à sua situação atual.
        </p>
      </div>


      <ExpandableComponent name={"Como funciona o atendimento online"} >
        <p className='p-4'>O atendimento acontece de forma ONLINE</p>
        <ul className="px-4 list-disc">
          <li className="mb-2">
            A consulta online acontece por chamada de vídeo pelo <strong>google meet ou zoom</strong>. Uma hora antes da nossa consulta acontecer, lhe encaminharei um link via WhatsApp.
          </li>
          <li className="mb-2">
            A partir do momento em que você marcar um horário comigo, eu irei te enviar um <strong>link de questionário pré-consulta</strong> para você responder algumas informações importantes sobre você, seu estilo de vida, queixa principal e outras questões importantes para avaliação.
          </li>
          <li className="mb-2">
            A primeira consulta leva em torno de 1h-1h30, nesse primeiro momento vamos conversar sobre o questionário respondido, conversaremos sobre sua trajetória, seu estilo de vida, sua alimentação, rotina, hábitos, dificuldades e queixas. E assim, alinharemos juntas alguns pontos para dar início ao tratamento de Ayurveda.
          </li>
          <li className="mb-2">
            <strong>Em até 7 dias úteis é entregue um plano terapêutico totalmente individualizado com todas as orientações e indicações de fitoterápicos alinhados ao olhar do Ayurveda.
            </strong></li>
          <li className="mb-2">
            Após 30-40 dias faremos nosso primeiro retorno online para avaliarmos como foi sua adaptação às orientações sugeridas, com duração de 30 a 40 minutos via google meet. E após mais 30 dias do primeiro retorno faremos seu segundo retorno online, completando um ciclo de tratamento. (Sempre entre em contato para marcarmos seus retornos). Esse tratamento pode ser estendido caso desejado ou necessário.
          </li>
          <li>
            Entre um atendimento e outro, sempre estarei disponível para você tirar suas dúvidas e compartilhar comigo o que está funcionando e o que já não está funcionando, alinhando o possível via WhatsApp, mas após 30-40 dias teremos seu retorno!
          </li>
        </ul>
        <p>
        </p>
      </ExpandableComponent >
      <ExpandableComponent name={"O que está incluso?"} >
        <ul className='px-4 list-disc '>
          <li className="mb-2">Plano Terapêutico individualizado com o olhar do Ayurveda, mas se necessário com técnicas da Naturologia </li>
          <li className="mb-2">Orientações de ervas e fitoterápicos </li>
          <li className="mb-2">Materiais exclusivos (caso necessário)</li>
          <li className="mb-2">2 retornos inclusos (Os retornos são fundamentais durante o tratamento para alinharmos o está funcionando e o que não está, além de tirar dúvidas e adaptar o plano)</li>
        </ul>
      </ExpandableComponent >
      <ExpandableComponent name={"Como o Ayurveda pode me ajudar?"} >
        <ul className='px-4 list-disc '>
          <li className="mb-2">Como prevenção e manutenção da saúde</li>
          <li className="mb-2">Como forma de cuidar e aumentar a vitalidade\imunidade</li>
          <li className="mb-2">Reequilibrar e harmonizar o corpo e a mente</li>
          <li className="mb-2">Depressão, ansiedade, insônia e estresse</li>
          <li className="mb-2">Questões gastrointestinais, cardíacas, ginecológicas, pulmonares</li>
          <li className="mb-2">Alergias, bronquite, rinite e sinusite</li>
          <li className="mb-2">Alguns exemplos: refluxo, má digestão, infertilidade, problemas circulatórios, problemas de pele como acne e rosácea, regular ciclos, prisão de ventre, disenteria, hipertensão, colesterol alto, diabetes, problemas relacionados a comida, dificuldade de concentração, falta de libido… </li>
          <li className="mb-2">Entre outros, sempre olhando para o físico, mental, vital, metabólico e espiritual. O tratamento e os resultados vão depender de cada caso específico, mas também da dedicação e interesse de cada pessoa </li>
        </ul>

      </ExpandableComponent >
      <ExpandableComponent name={"Plano de tratamento"} >
        <p className='p-4'>No nosso atendimento, após alinharmos as expectativas, traçaremos um plano de tratamento, sempre atendendo às suas necessidades de acordo com sua disponibilidade de tempo e também financeiro.</p>
        <p className='p-4'>Acredito que um tratamento regular pode trazer maior eficiência! </p>
        <p className='p-4'>Caso seja necessário que você faça algumas terapêuticas de forma presencial, tal como: abhyanga, basti, shirodhara, garshana entre outras práticas, explicitadas no plano de terapêutico e/ou conversadas, será avisado previamente, tendo seus custos a parte do valor da consulta.
        </p>
        <p className='p-4'>Nossos encontros e retornos acontecem dentro do período de 3 meses, dependendo das suas necessidades e demandas, online, presencialmente ou híbrido, com ou sem pacotes de terapêuticas práticas. </p>


      </ExpandableComponent >
      <ExpandableComponent name={"Valores"} >
        <AtendimentoInfo
          firstAppointment={{ "min": 180, "max": 200 }}
          otherAppointments={{ "min": 150, "max": 160 }}
        />
      </ExpandableComponent >
      <ExpandableComponent name={"Formas de pagamento"} >
        <PaymentOptions />
      </ExpandableComponent >


      <Contato />
    </div>
  );
};

export default ServicoNatu;
