import React from 'react';
import { AiFillInstagram, AiOutlineInstagram } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';

const ContactPage = () => {
  const redirectToWhatsApp = () => {
    window.open('https://wa.link/mcsago', '_blank'); // Replace '1234567890' with your phone number
  };

  return (
    <div className="py-24">
      <div className="container mx-auto p-4 md:p-8">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-8">
            Contato
          </h1>

          
          <div className="flex flex-col items-center space-y-4">
            <p className="text-xl text-center mb-4">
              Sinta-se à vontade para entrar em contato comigo para tirar suas dúvidas e/ou agendar uma consulta.
            </p>

            <div className='grid md:grid-cols-2 w-full text-center'>
            <div className="flex items-center justify-center space-x-2">
              <a 
                href='https://www.instagram.com/laura.naturologia' 
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram className="text-2xl" />
              </a>
              
              <a
                href="https://www.instagram.com/laura.naturologia"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-800 font-semibold"
              >
                @laura.naturologia
              </a>
            </div>
            <div className="flex items-center justify-center space-x-2 mt-2 md:mt-0">
              <a 
                href='https://wa.link/mcsago' 
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoWhatsapp className="text-2xl" />
              </a>
              
              <a
                href="https://wa.link/mcsago"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-800 font-semibold"
              >
                WhatsApp
              </a>
            </div>
          </div>

            
              
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
