import { Link } from "react-router-dom";

import React from 'react';
import { AiFillInstagram, AiOutlineInstagram } from 'react-icons/ai';
import { IoIosColorPalette, IoLogoDesignernews, IoLogoGithub, IoLogoWhatsapp } from 'react-icons/io';

const Footer = () => {
  return (
    <footer className="bg-[#425d3c] text-white py-8 relative">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col items-center space-y-4">
          <Link href="/">
            <h1 className="text-xl font-semibold">Lau Naturóloga</h1>
          </Link>
          
          
          <div className="flex space-x-4">
            <a
              href="https://www.instagram.com/laura.naturologia"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl"
            >
              <AiFillInstagram />
            </a>
            <a
              href="https://wa.link/mcsago"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl"
            >
              <IoLogoWhatsapp />
            </a>
          </div>
        </div>
      </div>
          <a
              href="https://www.luisagomes.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[26px] absolute z-10 bottom-[6.5px] right-11"
          >
              <IoIosColorPalette />
          </a>
          
          <a
              href="https://github.com/schwarzam"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl absolute z-10 bottom-2 right-2"
          >
              <IoLogoGithub />
          </a>
          <p className="text-center pt-4">
            &copy; {new Date().getFullYear()}.
          </p>
    </footer>
  );
};

export default Footer;
