import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import Services from './services/index'

const Naturologia = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <div className="pt-16" id="naturologia">
      <div className="container mx-auto p-4 md:p-8">
        <h1 className="text-3xl px-6 md:px-0 md:text-center md:text-6xl font-bold md:mb-4">
          O que é a Naturologia?
        </h1>
        <div
          ref={ref}
          className="p-6 py-2 md:py-6 rounded-md md:text-justify"
        >
          <motion.div
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
            transition={{ duration: 1 }}
          >
            <p className="mb-4">
              A Naturologia é um ramo relativamente novo dentro da área da saúde, que busca a manutenção da saúde por meio das Práticas Integrativas e Complementares (PICs). É considerada uma área com diferentes alicerces uma vez que surgiu da fragmentação e da especialização do cuidado, tendo a base médica sobre fisiologia e corpo humano da medicina ocidental, mas trazendo como pilares fundamentais as medicinas tradicionais Chinesa, Ayurvédica e Botânica.
            </p>
            <p className="mb-4">
              Para melhor entendimento podemos pensar em um exemplo: Suponhamos que uma mulher sinta dor de cabeça constantemente. Nós naturólogos observamos essa manifestação além do nível físico, tentando entender se isso pode estar sendo causado por uma alimentação desbalanceada ou até por um emocional abalado. Assim, procuramos interligar todos os níveis da saúde humana de uma forma mais ampla, com reflexões e mudanças de estilos, hábitos e rotinas.
            </p>
            <p className=''>
              O propósito da Naturóloga é a visão complexa (completa) do paciente, tendo-o como interagente, ou seja, um agente ativo no seu processo de cura, e assim com um olhar multidimensional, reavaliando o processo da saúde e doença nos níveis mental, físico, emocional, metabólico e vital. Visando uma melhora no sistema como um todo, reintegrando a harmonia do corpo e assim contribuindo ativamente com a função natural de reequilíbrio e auto-organização, além de promover autoconhecimento, autonomia e educação em saúde.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Naturologia;
