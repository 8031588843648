import React, { useEffect } from 'react';
import Naturologia from '../natu'
import Services from '../services';
import Contato from '../contato';

const Natu = () => {

  return (
    <div className="max-w-5xl m-auto min-h-screen">
      <Naturologia />
      <Services toDisplay={["Naturologia • Saúde Integral", "Doula Naturóloga", "Ayurveda", "Consultas Floral de Bach", "Consultas de Aromaterapia"]} />
      <Contato />
    </div>
  );
};

export default Natu;
