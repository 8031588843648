import QueENaturologia from './natu'
import Focos from './Focos'
import ContactPage from './contato'


import ScrollingImages from './imagespanel'

export default function Home() {
  return (
    <div className="">
      <div className='max-w-5xl m-auto'>
      <div className="flex flex-col md:flex-row items-center justify-center h-full">
        
        <div className="flex-1 text-center p-8 md:py-16">
          <img
            className="mx-auto w-full md:max-w-md"
            src="flor natu.png"
            alt="Naturology"
          />
        </div>
        <div className="flex-1 p-8 md:py-16">
          <h1 className="text-3xl md:text-[52px] font-bold">Olá, eu sou a Lau</h1>
          <p className="text-[16px] pb-4 pt-1 ">
            me chamo Laura, mas pode me chamar de Lau
          </p>
          <p></p>
          <p className="text-left mb-4 hyphenate">
          Sou naturóloga graduada pela UAM, doula, placenteira, pós graduanda em Ayurveda pelo Instituto de Cultura Hindu Naradeva Shala, me especializando em ginecologia natural, aromaterapia e empreendedora. 
            {/* E sou encantada e completamente apaixonada pela natureza e sua potência, acredito que tudo que a natureza nos fornece é o essencial para viver em harmonia com o todo. */}
          </p>
          <p className="text-left mb-4 hyphenate">
          Apaixonada pela cosmetologia biocompatível, tenho minha própria empresa de cosméticos a <a className='underline'   
              href="https://instagram.com/inspiraflor__"
              target="_blank"
              rel="noopener noreferrer" 
              >@InspiraFlor</a>. 
          </p>
          <p className="text-left hyphenate">
            Completamente encantada pela natureza, tenho convicção de que tudo que ela nos oferece é exatamente o que precisamos para viver juntos, em harmonia e respeito, com o mundo que nos cerca.
          </p>
        </div>
      </div>
      
      
          <QueENaturologia />
          <ScrollingImages />
          <Focos />
          <ContactPage />
      </div>
      
    </div>
  )
}
