import React, { useEffect } from 'react';
import Meuatendimento from '../services/meuatendimento'
import Contato from '../contato'
import ExpandableComponent from '../expandable'
import AtendimentoInfo from '../services/valores'
import PaymentOptions from '../services/formas_de_pagamento'


const ServicoNatu = () => {

  return (
    <div className="max-w-3xl m-auto min-h-screen py-16">
      <h1 className='text-3xl text-center md:text-4xl'>Consulta Ginecologia Natural</h1>
      <div className="p-4">
        <p className='mb-4'>
          Este é um movimento acolhedor e uma rica fonte de conhecimento, apresentando um caminho terapêutico que coexiste harmoniosamente com a medicina convencional, nunca visando substituí-la. Sua meta é fornecer uma abordagem complementar, baseada em três sólidos pilares:
        </p>
        <ul className="p-4 list-disc">
          <li className="mb-2">
            Autonomia Feminina: Nós, como mulheres, somos as donas de nosso próprio corpo, sentimentos e decisões. Somos as protagonistas de nossa história, responsáveis por nosso bem-estar.
          </li>
          <li className="mb-2">
            Autocuidado: Com essa autonomia, adquirimos a habilidade de explorar diversos recursos para nosso próprio cuidado.
          </li>
          <li className="mb-2">
            Autoconhecimento: Por meio de uma compreensão mais profunda de nós mesmas, podemos implementar efetivamente o autocuidado e exercer plena autonomia.
          </li>
        </ul>



        <p className='mb-4'>
          A Ginecologia Natural é mais do que um simples remédio à base de ervas para aliviar um mal-estar. É uma maneira mais profunda e integrada de ver nossa saúde, compreendendo-nos como o centro dela, buscando caminhos mais harmoniosos para o nosso corpo - que é, em essência, uma manifestação da natureza.
        </p>
        <p className='mb-4'>
          Na Ginecologia Natural, reconhecemos que tudo o que se manifesta em nosso corpo físico está relacionado a múltiplos fatores. Diferentemente da medicina ocidental, que costuma tratar os sintomas ignorando o contexto de vida, cotidiano e emoções, adotamos um olhar mais integrativo para nossa saúde.
        </p>
      </div>
      <ExpandableComponent name={"Por que buscar a Ginecologia Natural e como ela pode me ajudar?"} >

        <p className='p-4'>Muitas vezes, nossas dores estão ligadas a nossas emoções, nosso espírito, nossa história de vida, nosso contexto social, nossas experiências de vida e a maneira como nos relacionamos com nosso corpo.
        </p>

        <p className='p-4'>
          Devemos reconhecer que temos a capacidade de cuidar de nós mesmas. Seja em parceria com uma doula, naturóloga, ginecologista ou médica de família - em todas as circunstâncias, nossa voz tem valor.
        </p>

        <p className='p-4 pb-0'>
          Alguns assuntos que podem ser tratados:
        </p>

        <ul className="px-8 list-disc">
          <li> Percepção da fertilidade e métodos contraceptivos </li>
          <li> Ciclicidade, Mandala Lunar, entender como funciona o seu ciclo/corpo </li>
          <li> Tratamento complementar para Endometriose </li>
          <li> Tratamento complementar para SOP e Ovários policísticos </li>
          <li> Tratamento complementar para Miomas </li>
          <li> Mulheres tentantes (desejo em engravidar) </li>
          <li> Cólicas intensas e incapacitantes. desconfortos durante o período menstrual </li>
          <li> Reequilíbrio hormonal </li>
          <li> Corrimentos, candida, fungos, doenças transmissíveis, infecções urinárias </li>
          <li> Sintomas da menopausa/climatério </li>
          <li> Entre outros !!! </li>
        </ul>
      </ExpandableComponent >
      <ExpandableComponent name={"Como funciona atendimento?"} >
        <p className='p-4'>O atendimento acontece de forma ONLINE</p>
        <ul className="px-4 list-disc">
          <li className="mb-2">
            A consulta online acontece por chamada de vídeo pelo<strong> google meet ou zoom.</strong> Uma hora antes da nossa consulta acontecer, lhe encaminharei um link via WhatsApp.
          </li>
          <li className="mb-2">
            A partir do momento em que você marcar um horário comigo, eu irei te enviar um <strong>link de questionário pré-consulta</strong> para você responder algumas informações importantes sobre você e queixas.
          </li>
          <li className="mb-2">
            A <strong>primeira consulta leva em torno de 1h-1h30</strong>, nesse primeiro momento vamos conversar sobre o questionário respondido, conversaremos sobre sua trajetória, seu estilo de vida, sua alimentação, rotina, hábitos, dificuldades e queixas. E assim, alinharemos juntas alguns pontos para dar início ao tratamento e terapêuticas que iremos utilizar.
          </li>
          <li className="mb-2">
            Em até 7 dias úteis é entregue um plano terapêutico totalmente individualizado com todas as orientações e indicações de fitoterápicos, caso seja necessário.
          </li>
          <li className="mb-2">
            Após 30-40 dias faremos um retorno online para avaliarmos como foi sua adaptação às orientações sugeridas com duração de 30 a 40 minutos via google meet.
          </li>
          <li className="mb-2">
            Entre um atendimento e outro, sempre estarei disponível para você tirar todas suas dúvidas e compartilhar comigo o que está funcionando e o que já não está funcionando, alinhando o possível via WhatsApp, mas após 30-40 dias teremos seu retorno!
          </li>

        </ul>
      </ExpandableComponent >
      <ExpandableComponent name={"O que está incluso?"} >
        <ul className='px-4 list-disc '>
          <li className="mb-2">Plano Terapêutico da interagente. </li>
          <li className="mb-2">Orientações de fitoterápicos.</li>
          <li className="mb-2">Materiais exclusivos (caso necessário).</li>
        </ul>
        <p className='p-4 pt-8 font-bold'>Kit Terapêutico: </p>
        <p className="px-4">
          O Kit Terapêutico sempre terá incluso <strong>2 ferramentas</strong> de tratamento de acordo com a necessidade do tratamento, podendo ser:
        </p>
        <ul className="px-4 list-disc">
          <li className="mb-2">
            Inalador pessoal
          </li>
          <li className="mb-2">
            Sinergia aromática com óleos essenciais (10ml)
          </li>
          <li className="mb-2">
            Um floral de Bach (30ml)
          </li>
          <li className="mb-2">
            Escalda-pés/banho aromático (cerca de 100g)
          </li>
        </ul>
        <p className="p-4">
          *** O atendimento de Ginecologia Natural e Naturologia são quase idênticos :)
        </p>
      </ExpandableComponent >
      <ExpandableComponent name={"Plano de tratamento"} >
        <p className='p-4'>No nosso atendimento, após alinharmos as expectativas, traçaremos um plano de tratamento, sempre atendendo às suas necessidades de acordo com sua disponibilidade de tempo e também financeiro.
        </p>
        <p className="p-4">
          Acredito que um tratamento regular pode trazer maior eficiência!
        </p>
        <p className="p-4">
          Nossos encontros acontecem dependendo das suas necessidades e demandas, podendo ser semanalmente, a cada 15 dias, a cada 20 dias, 30 dias ou a cada 60 dias, dando assim uma constância ao tratamento.
        </p>
        <p className="p-4">
          Isso será combinado pelo WhatsApp/ pós primeira consulta.
        </p>

      </ExpandableComponent >
      <ExpandableComponent name={"Valores"} >
        <AtendimentoInfo
          firstAppointment={{ "min": 180, "max": 200 }}
          otherAppointments={{ "min": 130, "max": 140 }}
        />
      </ExpandableComponent >
      <ExpandableComponent name={"Formas de pagamento"} >
        <PaymentOptions />
      </ExpandableComponent >


      <Contato />
    </div>
  );
};

export default ServicoNatu;
