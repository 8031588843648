import React, { useEffect, useRef } from 'react';

export default function ScrollingImages() {
  const scrollRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft += 1;
      }
    }, 20);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex overflow-x-hidden py-16 md:mx-12">
      <div className="flex animate-scroll whitespace-nowrap">
        <div className="flex px-4">
            <img className="h-48 md:h-64 mr-3" src="panel_images/im1.jpeg" alt="Image 1"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im2.jpeg" alt="Image 2"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im3.jpeg" alt="Image 3"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im4.jpeg" alt="Image 4"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im5.jpeg" alt="Image 5"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im6.jpeg" alt="Image 6"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im7.jpeg" alt="Image 7"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im8.jpeg" alt="Image 8"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im9.jpeg" alt="Image 9"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im10.jpeg" alt="Image 10"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im11.jpeg" alt="Image 11"/>
            <img className="h-48 md:h-64 mr-3" src="panel_images/im12.jpeg" alt="Image 12"/>
            {/* Add as many images as you want */}
            
        </div>
      </div>
    </div>
  );
}