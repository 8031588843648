import React, { useEffect } from 'react';
import Meuatendimento from '../services/meuatendimento'
import Contato from '../contato'
import ExpandableComponent from '../expandable'
import AtendimentoInfo from '../services/valores'
import PaymentOptions from '../services/formas_de_pagamento'


const ServicoNatu = () => {

  return (
    <div className="max-w-3xl m-auto min-h-screen py-16">
        <h1 className='text-3xl text-center md:text-4xl'>Consulta Florais de Bach</h1>

          <div className=''>
            <div className="shadow-md overflow-hidden my-10">
              <div className="md:flex items-center"> {/* Add items-center here */}
                <div className="md:flex-shrink-0">
                  <img className="h-64 w-full object-cover" src="/panel_images/crabapple.jpg" alt="Dr. Edward Bach"/>
                </div>
                <div className="mt-4 md:mt-0 md:mx-6 text-center">
                  <p className="block mt-1 text-lg leading-tight font-medium text-black">“Encoraje seu paciente em sua individualidade e ensine-o a olhar para a frente."</p>
                  <div className="uppercase tracking-wide text-sm font-semibold">Dr. Edward Bach</div>
                </div>
              </div>
            </div>


          <p className='mb-4'>
          Os Florais de Bach são uma terapia natural e complementar desenvolvida pelo médico inglês Edward Bach na década de 1930. Essa terapia consiste em utilizar essências florais para tratar questões emocionais e mentais, buscando promover o equilíbrio e bem-estar do indivíduo.
          </p>
          <p className='mb-4'>
          O sistema Floral de Bach é composto por 38 florais, sendo 37 essências florais, 1 essência mineral e 1 composto emergencial. As essências florais são extratos líquidos sutis, geralmente ingeridos por via oral.
          </p>
          <p className='mb-4'>
          As essências florais são preparadas a partir de elementos da natureza, que são obtidos em locais específicos e em momentos específicos, de acordo com a filosofia de Bach. Cada essência floral possui uma energia e vibração única, que pode ser utilizada para tratar diferentes aspectos emocionais, mentais e físicos. 
          </p>
          <p className='mb-4'>
         A Terapia Floral é uma prática terapêutica que busca trazer autoconhecimento e novas possibilidades de escolhas, atuando como um catalisador no despertar de virtudes e qualidades. Além disso, tem caráter social, preventivo e de promoção da saúde, fortalecendo as responsabilidades pessoal e social, atuando no resgate da integridade e cidadania do ser humano.
          </p>
        </div>

        <ExpandableComponent name={"Como esse seviço pode me ajudar?"} >
          <p className='p-4'>Os Florais de Bach servem para diversas situações, como aliados da nossa saúde mental, emocional e até mesmo física, eles agem na causa e não nos sintomas, e não possuem nenhuma contra indicação. Aqui estão algumas situações em que os florais podem ajudar:</p>
          <ul className="px-4 list-disc">
                <li className="mb-2">
                Ansiedade
                </li>
                <li className="mb-2">
                Depressão
                </li>
                <li className="mb-2">
                Síndrome do pânico 
                </li>
                <li className="mb-2">
                Cansaço
                </li>
                <li className="mb-2">
                Fadiga
                </li>
                <li className="mb-2">
                Falta de disposição
                </li>
                <li className="mb-2">
                Sono
                </li>
                <li className="mb-2">
                Vícios (nicotina entre outros)
                </li>
        </ul>
        </ExpandableComponent >
        <ExpandableComponent name={"Como funciona atendimento?"} >
          <p className='p-4'>O atendimento acontece de forma ONLINE</p>
          <ul className="px-4 list-disc">
                <li className="mb-2">
                A consulta online acontece por chamada de vídeo pelo google meet ou zoom. Uma hora antes da nossa consulta acontecer, lhe encaminharei um link via WhatsApp. 
                </li>
                <li className="mb-2">
                A partir do momento em que você marcar um horário comigo, eu irei te enviar um link de questionário pré-consulta para você responder algumas informações importantes sobre você e queixas. 
                </li>
                <li className="mb-2">
                A primeira consulta leva em torno de 45 a 60 minutos, neste primeiro momento vamos conversar sobre o questionário respondido. E assim, alinharemos juntas alguns pontos para dar início ao tratamento.
                </li>
                <li className="mb-2">
                Em até 5 dias úteis seu floral estará pronto, se desejar, você também poderá solicitar a receita para manipular em uma farmácia. Assim poderemos combinar a entrega, correio ou retirada no metrô.
                </li>
                <li className="mb-2">
                Entre um atendimento e outro, sempre estarei disponível para você tirar todas suas dúvidas e compartilhar comigo suas percepções fazendo o uso do floral, via WhatsApp!
                </li>
            </ul>
        </ExpandableComponent >
        <ExpandableComponent name={"O que está incluso?"} >
        <ul className='px-4 list-disc '>
            <li className="mb-2">Consulta inicial e anamnese detalhada </li>
            <li className="mb-2">Avaliação personalizada e seleção das essências florais adequadas</li>
            <li className="mb-2">Prescrição das essências e orientações sobre como usá-las</li>
            <li className="mb-2">Seu floral personalizado, e se desejado, uma receita para a manipulação. (O floral personalizado está incluso no valor da consulta).</li>
          </ul>
          <p className='p-4 font-bold'>IMPORTANTE: </p>
          <ul className="px-4 list-disc">
                <li className="mb-2">
                O floral personalizado está incluso no valor da consulta.
                </li>
                <li className="mb-2">
                Os valores das entregas físicas não estão inclusos.
                </li>
                <li className="mb-2">
                As entregas são feitas por correio ou metrô (combinar estação e dia).
                </li>
            </ul>
        </ExpandableComponent >
        <ExpandableComponent name={"Plano de tratamento"} >
          <p className='p-4'>A periodicidade recomendada é de uma vez ao mês para reavaliação da indicação do Floral.  O tempo de tratamento varia de forma individual, mas o floral dura em torno de 25-30 dias usando de acordo com o recomendado.</p>
          <p className="p-4">
            Acredito que um tratamento regular pode trazer maior eficiência! 
          </p>
          <p className="p-4">
            Nossos encontros acontecem dependendo das suas necessidades e demandas, a cada 30 dias ou a cada 60 dias, dando assim uma constância ao tratamento. Isso será combinado pelo WhatsApp/ após primeiro atendimento. 
          </p>
        </ExpandableComponent >
        <ExpandableComponent name={"Valores"} >
          <AtendimentoInfo 
            firstAppointment={{"value": 130}}
            otherAppointments={{"value": 100}} 
          />
        </ExpandableComponent >
        <ExpandableComponent name={"Formas de pagamento"} >
          <PaymentOptions />
        </ExpandableComponent >
        

        <Contato />
    </div>
  );
};

export default ServicoNatu;
