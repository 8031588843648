// Este será o seu componente
const PaymentOptions = () => {
    return (
        <div className="rounded-md">
            <p>Todos métodos de pagamento são à vista (Não aceito parcelamento).</p>
            <ul className="list-disc pl-5 mt-4">
                <li>Via PIX</li>
                <li>Transferência (TED)</li>
            </ul>
        </div>
    )
}

export default PaymentOptions;