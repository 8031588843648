import React, { useEffect } from 'react';
import Meuatendimento from '../services/meuatendimento'
import Contato from '../contato'
import ExpandableComponent from '../expandable'

const Parcerias = () => {

  return (
    <div className="max-w-3xl m-auto min-h-screen py-16">
        <h1 className='text-3xl text-center md:text-4xl'>Parcerias</h1>

        <div className='p-4'>
          <p className='mb-4'>
          <strong>Prezados parceiros em potencial,   </strong>       
          </p>
          <p className='mb-4'>
          É com grande entusiasmo que ofereço a oportunidade de estabelecer parcerias nas áreas de <strong>Ayurveda, Saúde da Mulher, Doulagem, Medicina Placentária, Cosmetologia Natural, Aromaterapia e Terapias Integrativas</strong>. Estou comprometida em compartilhar meu conhecimento e experiência nessas áreas por meio de <strong>workshops, palestras, cursos, retiros, mentorias, lives, produtos personalizados e aulas</strong>. Gostaria de apresentar a vocês como essas parcerias podem ser benéficas para seus projetos.
          </p>

          <ExpandableComponent name={'Ayurveda'}>
            O Ayurveda é um sistema de medicina tradicional da Índia que enfatiza o equilíbrio entre corpo, mente e espírito. Posso fornecer uma compreensão abrangente dos princípios ayurvédicos, como a alimentação adequada, rotinas diárias saudáveis, práticas de bem-estar e demais técnicas.
          </ExpandableComponent>

          <ExpandableComponent name={'Consciência Ambiental (como ter uma vida sustentável e ecológica)'}>
            A Consciência Ambiental envolve ações e práticas voltadas para a preservação do meio ambiente e um estilo de vida sustentável. Podemos estabelecer parcerias oferecendo palestras, mentorias e oficinas sobre sustentabilidade, consultoria para empresas interessadas em adotar práticas sustentáveis e participação em projetos de responsabilidade ambiental em colaboração com ONGs, escolas, instituições governamentais ou empresas.
          </ExpandableComponent>

          <ExpandableComponent name={'Saúde da Mulher'}>
           A saúde da mulher é uma área crucial que merece atenção especial. Posso abordar tópicos como saúde hormonal, cuidados naturais para o ciclo menstrual, nutrição adequada durante a gravidez, cuidados na menopausa, e práticas de autocuidado para o bem-estar geral das mulheres.
          </ExpandableComponent>

          <ExpandableComponent name={'Cosmetologia Biocompatível'}>
            A crescente demanda por produtos de beleza e cuidados pessoais naturais e sustentáveis oferece uma excelente oportunidade de parceria. Posso oferecer conhecimentos e ensinamentos sobre a formulação de cosméticos naturais, ingredientes seguros, técnicas de produção e marketing consciente. Além disso, trabalho com a produção de produtos de cosmetologia natural, podendo elaborar produtos personalizados em colaborações em lojas dos mais diversos segmentos.
          </ExpandableComponent>

          <ExpandableComponent name={'Terapias Integrativas'}>
            As terapias integrativas abrangem uma variedade de abordagens terapêuticas, como fitoterapia, aromaterapia, florais de bach, arteterapia, trofoterepia massagens entre outras diversas técnicas. Ofereço práticas e conhecimentos que fornecem uma introdução abrangente a essas terapias, explorando seus benefícios e aplicações para a saúde e o bem-estar.          
          </ExpandableComponent>
          
          <ExpandableComponent name={'Aromaterapia'}>
            A Aromaterapia é uma prática terapêutica que utiliza óleos essenciais para promover o bem-estar físico, emocional e mental. Podemos estabelecer parcerias oferecendo workshops, palestras e cursos sobre Aromaterapia, além de criar produtos personalizados em colaboração com spas, clínicas de estética ou lojas de produtos naturais.
   
          </ExpandableComponent>

          <ExpandableComponent name={'Medicina Placentária'}>
            A medicina placentária tem ganhado reconhecimento por seus potenciais benefícios para o pós-parto. Posso abordar tópicos sobre o processamento seguro da placenta e a utilização de seus derivados para promover uma recuperação saudável, equilíbrio hormonal e apoio emocional para as mães e família. 
          </ExpandableComponent>

          <ExpandableComponent name={'Doulagem'}>
            A Doulagem é um suporte emocional e físico oferecido por uma doula durante a gestação, parto e pós-parto. Podemos estabelecer parcerias com profissionais da área médica para oferecer suporte durante o parto, realizar workshops e cursos para gestantes, casais e profissionais de saúde, além de desenvolver materiais educativos sobre a importância do suporte emocional durante o parto.  
          </ExpandableComponent>

          <p className='my-4'>
          Lembrando que esses são apenas resumos, e cada parceria pode ser adaptada de acordo com as necessidades e objetivos específicos de cada situação.          
          </p>

          <p className='mb-4'>
          Minhas parcerias são personalizadas para atender às necessidades específicas de sua empresa ou comunidade. Trabalharemos juntos para criar eventos educativos envolventes, adaptados aos seus objetivos e público-alvo. Meu compromisso é fornecer informações claras, práticas e de fácil compreensão, com foco em promover a saúde, a cura e o equilíbrio em todos os níveis.    
          </p>

          <p className='mb-4'>
          Convido você a explorar essas áreas fascinantes e valiosas por meio de nossas parcerias. Estou ansiosa para colaborar com você e ajudar a melhorar a qualidade de vida das pessoas por meio do conhecimento integrativo e natural e das práticas terapêuticas.
         
          </p>


          <p className='mb-4'>
          Para mais informações e para discutir as possibilidades de parceria por favor, entre em contato comigo. Será um prazer responder a todas as suas perguntas e juntos explorarmos como podemos trabalhar em conjunto.
     
          </p>
 
        </div>

        <Contato />
    </div>
  );
};

export default Parcerias;
