

// Este será o seu componente
const AtendimentoInfo = ({ firstAppointment, otherAppointments }) => {
    return (
        <div className="rounded-md">
            <ul className="list-disc pl-5 mt-4">
                <li>
                    Primeiro atendimento: 
                    <strong>{firstAppointment.min && firstAppointment.max ? 
                                        ` entre R$${firstAppointment.min} - R$${firstAppointment.max}` : 
                                                            ` R$${firstAppointment.value}`}</strong>
                </li>
                <li>
                    Demais atendimentos: 
                    <strong>{otherAppointments.min && otherAppointments.max ? 
                                        ` entre R$${otherAppointments.min} - R$${otherAppointments.max}` :
                                                            ` R$${otherAppointments.value}`}
                                                            </strong></li>

            </ul>
            <p className="mt-4">Caso os valores estejam fora de seu orçamento, estou sempre disposta para conversar e alinhar o melhor para ambas as partes.</p>
            <p className="mt-2">Para fechar pacotes entre em contato via Whatsapp!</p>
        </div>
    )
}

export default AtendimentoInfo;
