import React, { useEffect } from 'react';
import Contato from '../contato'
import ExpandableComponent from '../expandable'
import AtendimentoInfo from '../services/valores'
import PaymentOptions from '../services/formas_de_pagamento'

const ServicoNatu = () => {

  return (
    <div className="max-w-3xl m-auto min-h-screen py-16">
        <h1 className='text-3xl text-center md:text-4xl'>Atendimento Naturologia</h1>

        <div className='p-4'>
          <p className='mb-4'>
          Juntas iremos olhar para você como um <strong>ser multidimensional</strong>, analisando todos os aspectos que o compõem: <strong>físico, mental, metabólico e vital</strong>, sempre procurando estabelecer uma <strong>harmonia com o todo</strong>. 
          </p>
          <p className='mb-4'>
          Minha missão e meta é te auxiliar ao máximo, a entender seus padrões de desequilíbrio e fazer o melhor que posso em sua vida, te dando caminhos e opções para lidar com os problemas de uma forma <strong>autônoma, consciente e natural</strong>, seja qual for o seu objetivo.
          </p>
          <p className='mb-4'>
          Trabalharemos juntas a sua <strong>autonomia</strong>, você será um <strong>agente ativo no seu processo de cura</strong>. Saiba que realizar esse contato já é um grande passo rumo aos seus objetivos.
          </p>
          <p className='mb-4'>
          Quero que saiba que a todo momento trabalharemos em um ambiente livre de julgamentos e completamente sigiloso, onde aos poucos vamos criar um espaço de acolhimento e vínculo.
          </p>
          <p className='mb-4'>
          Conte comigo!
          </p>
        </div>

        <ExpandableComponent name={"Como funciona atendimento online?"} >
          <Meuatendimento />
        </ExpandableComponent >
        <ExpandableComponent name={"Plano de tratamento"} >
          <p className='p-4'>No nosso atendimento, após alinharmos as expectativas, traçaremos um <strong>plano de tratamento</strong>, sempre atendendo às suas necessidades de acordo com sua disponibilidade de tempo e também financeiro. </p>
          <p className='p-4'>Acredito que um tratamento regular pode trazer maior eficiência!</p>
          <p className='p-4'>Nossos encontros acontecem dependendo das suas necessidades e demandas, online, presencialmente ou híbrido, podendo ser semanalmente, a cada 15 dias, a cada 20 dias, 30 dias ou a cada 60 dias, dando assim uma constância ao tratamento. Isso será combinado pelo WhatsApp/ pós primeira consulta. </p>
        </ExpandableComponent >
        <ExpandableComponent name={"O que está incluso?"} >
        <div className="p-4">
            <ul className="list-disc px-4">
                <li className="mb-2">
                Plano Terapêutico do interagente
                </li>
                <li className="mb-2">
                Orientações de fitoterápicos
                </li>
                <li className="mb-2">
                Materiais exclusivos (caso necessário)
                </li>
            </ul>
            
            <p className="pt-2"><strong>Kit Terapêutico: </strong></p>
            <p className="pb-2">O Kit Terapêutico sempre terá incluso <strong>2 ferramentas</strong> de tratamento de acordo com a necessidade do tratamento, podendo ser: </p>
            <ul className="list-disc px-4">
              <li className="mb-2">
                Inalador pessoal
              </li>
              <li className="mb-2">
                Sinergia aromática com óleos essenciais (10ml)
              </li>
              <li className="mb-2">
                Um floral de Bach (30ml) 
              </li>
              <li className="mb-2">
                Escalda-pés/banho aromático (cerca de 100g) 
              </li>
            </ul>
             

          </div>
        </ExpandableComponent>
        <ExpandableComponent name={"Valores"} >
          <AtendimentoInfo 
            firstAppointment={{"min": 180, "max": 200}}
            otherAppointments={{"min": 130, "max": 140}} 
          />
        </ExpandableComponent >
        <ExpandableComponent name={"Formas de pagamento"} >
          <PaymentOptions />
        </ExpandableComponent >
        

        <Contato />
    </div>
  );
};

export default ServicoNatu;

const Meuatendimento = () => {

  return (
    <div className="max-w-5xl m-auto py-4">
        
        <div className='px-4'>
        
          <div className="p-2">
            <ul className="list-disc">
                <li className="mb-2">
                A consulta online acontece por <strong>chamada de vídeo pelo google meet ou zoom</strong>. Uma hora antes da nossa consulta acontecer, lhe encaminharei um link via WhatsApp. 
                </li>
                <li className="mb-2">
                A partir do momento em que você marcar um horário comigo, eu irei te enviar um <strong>link de questionário pré-consulta</strong> para você responder algumas informações importantes sobre você, seu estilo de vida, queixa principal e outras questões importantes para avaliação.
                </li>
                <li className="mb-2">
                <strong>A primeira consulta leva em torno de 1h-1h30</strong>, nesse primeiro momento vamos conversar sobre o questionário respondido, conversaremos sobre sua trajetória, seu estilo de vida, sua alimentação, rotina, hábitos, dificuldades e queixas. E assim, alinharemos juntas alguns pontos para dar início ao tratamento e terapêuticas que iremos utilizar.
                </li>
                <li className="mb-2">
                <strong>Em até 7 dias úteis é entregue um plano terapêutico totalmente individualizado com todas as orientações e indicações de fitoterápicos</strong>, caso seja necessário.
                </li>
                <li className="mb-2">
                Após 30-40 dias faremos um <strong>retorno online</strong> para avaliarmos como foi sua adaptação às orientações sugeridas com duração de 30 a 40 minutos via google meet.
                </li>
                <li className="mb-2">
                Entre um atendimento e outro, sempre estarei disponível para você tirar todas suas dúvidas e compartilhar comigo o que está funcionando e o que já não está funcionando, alinhando o possível via WhatsApp, mas após 30-40 dias teremos seu retorno! 
                </li>
            </ul>
            </div>
        </div>
    </div>
  );
};
