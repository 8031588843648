import React from 'react';

const topics = [
  {
    "nome": "Ayurveda",
    "descricao": "O Ayurveda é baseado em uma compreensão da natureza e do universo e considera que o corpo humano é uma parte integrante deste todo. Trabalha rotina, estilo de vida, alimentação e hábitos daquela pessoa buscando equilíbrio e bem-estar"
  },
  {
    "nome": "Medicina Tradicional Chinesa",
    "descricao": "Um sistema milenar que aborda a saúde integral, utilizando técnicas como acupuntura, fitoterapia e alimentação para equilibrar o fluxo de energia no corpo."
  },
  {
    "nome": "Ginecologia Natural",
    "descricao": "Uma maneira mais profunda e integrada de ver nossa saúde, compreendendo-nos como o centro dela. Tendo como pilares a Autonomia Feminina, Autocuidado e Autoconhecimento."
  },
  {
    "nome": "Aromaterapia",
    "descricao": "Uso dos óleos essenciais que podem ser explicados por seus aspectos altamente voláteis, químicos, botânicos e energéticos/vitalistas. É uma ciência séria, que usada corretamente tem diversos benefícios à saúde."
  },
  {
    "nome": "Florais de Bach",
    "descricao": "Terapia que consiste em utilizar essências florais para tratar questões emocionais e mentais, buscando promover o equilíbrio e bem-estar do indivíduo."
  },
  {
    "nome": "Fitoterapia",
    "descricao": "Uso de plantas medicinais para tratamento e prevenção de doenças. Pode ter um enfoque preventivo, especializado e/ou complementar."
  },
  {
    "nome": "Cromoterapia",
    "descricao": "Tratamento que consiste na utilização das cores para aliviar sintomas de doenças e restaurar o equilíbrio físico e emocional. Muito utilizado em conjunto à acupuntura, massagem, auriculoterapia etc."
  },
  {
    "nome": "Auriculoterapia",
    "descricao": "Técnica derivada da acupuntura, que faz pressão em pontos específicos da orelha para tratar diversos problemas mentais emocionais e até mesmo físicos."
  },
  {
    "nome": "Geoterapia",
    "descricao": "Técnica que utiliza argilas medicinais para uso externo, como massagens, cataplasmas, emplastro entre outros. As argilas, independente de sua cor favorece a desintoxicação dos tecidos, promove a renovação celular, equilibra o pH, desintoxicante, ativadora da circulação etc "
  },
  {
    "nome": "Recursos Hidrominerais",
    "descricao": "A hidroterapia utiliza a água como meio terapêutico para promover a saúde e prevenir doenças."
  },
  {
    "nome": "Arte-integrativa",
    "descricao": "A arte-integrativa inclui diversas terapias que utilizam a expressão artística para promover a saúde mental e emocional."
  },
  {
    "nome": "Ventosaterapia",
    "descricao": "Terapêutica utilizada na Medicina Chinesa que se caracteriza por utilizar copos que criam um vácuo e promovem a circulação sanguínea, levando ao alívio de dores musculares e tensões."
  }
]

// const colors = ['425d3c', 'a55439', 'a55439', 'dad2c7', 'cc8035', 'dc906c', '76a1c3']
const colors = ['#425d3c', '#a55439', '#749474', '#dad2c7', '#cc8035', '#dc906c', '#76a1c3']

const TopicsPage = () => {
  return (
    <div className="pt-6">
      <div className="container mx-auto p-4 md:p-8">
        <h1 className="text-4xl text-center md:text-6xl font-bold md:mb-4 p-10">
          Terapêuticas utilizadas
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {topics.map((topic, index) => (
            <div
              key={index}
              className="relative p-6 rounded-md shadow-lg "
            >
              <div
                style={{ backgroundColor: `${colors[index % colors.length]}` }}
                className="absolute top-0 left-0 w-6 h-6"
              ></div>
              <h2 className={`font-bold text-xl text-center mb-2 text-[#4a2f1f]`}>{topic.nome}</h2>
              <p>
                {topic.descricao}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopicsPage;
