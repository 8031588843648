import ServiceCard from './servicecard';

export default function Services({ toDisplay }) {
  
  var services = [
    {
      image: 'panel_images/im1.jpeg',
      name: 'Naturologia • Saúde Integral',
      description: 'Atendimento online e/ou presencial de naturologia.',
      href: '/serviconatu',
      price: '$100',
    },
    
    {
      image: 'panel_images/im16.jpeg',
      name: 'Ayurveda',
      description: 'Atendimento online e/ou presencial de Ayurveda.',
      href: '/servicoayurveda',
      price: '$300',
    },
    {
      image: 'panel_images/im4.jpeg',
      name: 'Ginecologia Natural',
      description: 'Atendimento online de Ginecologia Natural.',
      href: '/servicogineconatural',
      price: '$300',
    },
    {
      image: 'panel_images/im20.jpeg',
      name: 'Doula Naturóloga',
      description: 'Acompanhamento na gestação, parto e pós-parto.',
      href: '/servicodoula',
      price: '$200',
    },
    {
      image: 'panel_images/im6.jpeg',
      name: 'Consultas Floral de Bach',
      description: 'Consulta exclusiva de Floral de Bach.             ',
      href: '/servicoflorais',
      price: '$300',
    },
    {
        image: 'panel_images/im8.jpeg',
        name: 'Consultas de Aromaterapia',
        description: 'Consulta exclusiva de Aromaterapia Clínica.',
        href: '/servicoaromaterapia',
        price: '$300',
    },
    
    {
      image: 'panel_images/im9.jpeg',
      name: 'Arte Placentária',
      description: 'Carimbo & Aquarela da Placenta.',
      href: '/servicoarteplacenta',
      price: '$300',
    }, 
    {
      image: 'panel_images/im2.jpeg',
      name: 'Parcerias',
      description: 'parcerias para cursos, workshops, aulas, palestras e retiros.',
      href: '/parcerias',
      price: '$300',
    },
  ];

  if (toDisplay) {
    const filteredServices = services.filter(service => toDisplay.includes(service.name));
    services = filteredServices;
  }

  return (
    <div className="py-6 sm:py-12">
      <div className="px-4 py-6 sm:max-w-5xl sm:mx-auto sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-center sm:justify-start sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              image={service.image}
              name={service.name}
              description={service.description}
              href={service.href}
              price={service.price}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
