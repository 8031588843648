import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import Navbar from './components/Navbar';
import Contato from './components/contato';
import Footer from './components/footer';
import Servicos from './components/services';
import Sobre from './components/sobre';
import Naturologia from './components/naturologia';

import ServicoAyurveda from './components/servicoayurveda';
import ServicoDoula from './components/servicodoula';
import ServicoFloral from './components/servicoflorais';
import ServicoGinecologia from './components/servicogineconatural';
import ServicoPlacenta from './components/servicoarteplacenta';
import ServicoAromaterapia from './components/servicoaromaterapia';
import ServicoNatu from './components/serviconatu';
import Parcerias from './components/parcerias';

function App() {
  return <Router>
    
    <Navbar />
    <Routes>
      
        <Route path="/" element={<Home />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/services" element={<Servicos />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/naturologia" element={<Naturologia />} />

        <Route path="/servicoayurveda" element={<ServicoAyurveda />} />
        <Route path="/servicodoula" element={<ServicoDoula />} />
        <Route path="/servicoflorais" element={<ServicoFloral />} />
        <Route path="/servicogineconatural" element={<ServicoGinecologia />} />
        <Route path="/servicoarteplacenta" element={<ServicoPlacenta />} />
        <Route path="/servicoaromaterapia" element={<ServicoAromaterapia />} />
        <Route path="/serviconatu" element={<ServicoNatu />} />
        <Route path="/parcerias" element={<Parcerias />} />

    </Routes>
    <Footer />
  </Router>
  
}

export default App;
