import { Link } from "react-router-dom";

function ProfileCard({ image }) {
  return (
    <div className="m-auto shadow-md rounded-md overflow-hidden w-full p-4 md:p-8 max-w-5xl">
      <div className="md:flex md:space-x-4">
        <div className="w-full h-64 md:w-1/2 md:h-auto relative mb-4 md:mb-0">
          <img
            src="/foto_lau.jpeg"
            alt="Profile Picture"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold mb-4">Sobre Mim</h1>
          <p className="mb-4">
            Sou naturóloga graduada pela UAM, doula, placenteira, estudiosa e empreendedora. Apaixonada pela cosmetologia biocompatível e natural, tenho minha própria empresa de cosméticos, a @InspiraFlor. Completamente encantada pela natureza, tenho convicção de que tudo que ela nos oferece é exatamente o que precisamos para viver juntos, em harmonia e respeito, com o mundo que nos cerca.
          </p>
          <p className="mb-4">
           Meu trabalho tem enfoque em três principais áreas: o cuidado da saúde integral da mulher, adolescente e criança com a Naturologia, abordagem individualizada de tratamento com o Ayurveda e acompanhamento como doula-naturóloga para gestantes, durante a gestação, parto e pós parto. 
          </p>
          <p className="mb-4">
            Acredito que informação é poder, busco promover assistência e segurança baseada
em evidências, promovendo a educação em saúde, autonomia e consciência no seu
processo, sempre com uma visão integral de sua complexidade.
          </p>
        </div>
      </div>
      <div className="mt-4 max-w-3xl">
        <h3 className="text-xl font-bold mb-4">Formações e especializações:</h3>
        <p className="mb-4">
        Ao longo da minha trajetória sempre busquei realizar cursos extracurriculares, sobre saúde da mulher, cosmetologia natural e biocompatível, entre outros conhecimentos em áreas relacionadas à saúde, alimentação e consciência ambiental.
        </p>
        <p>
        Aqui estão alguns dos cursos que já realizei/estou realizando: 
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Naturóloga formada pela UAM</li>
          <li>Doula e Medicina Placentária, ''En la Luz de la Partería''</li>
          <li>Formada em Ginecologia Natural por Thais Messias Moraes</li>
          <li>Pós graduanda em Ayurveda pelo Instituto de Cultura Hindu Naradeva Shala</li>
          <li>Formanda em Aromaterapia pela escola Apotecários da Floresta</li>
          <li>Formanda no curso da Naoli Vinaver ‘A arte do Parto’</li>
          <li>Atuação com medicinas placentárias e arte da placenta</li>
        </ul>
        <p className="mb-4">
          Essas experiências me proporcionaram uma base sólida de conhecimentos nas áreas de saúde da mulher, cosmetologia natural e biocompatível, além de outros campos relacionados à saúde, alimentação e consciência ambiental.        </p>
        <p className="mb-4">
        Estou empenhada em fornecer cuidados personalizados e eficazes, trazendo minha paixão pela natureza e meu compromisso com a saúde integral. Espero poder compartilhar minha expertise e ajudar você em sua jornada rumo ao bem-estar e à harmonia.
        </p>


        <p>
          Fique à vontade para entrar em contato comigo para saber mais ou agendar uma consulta. Estou ansiosa para poder auxiliá-la em seu caminho de saúde e equilíbrio.
        </p>
        <p>
        Espero que isso seja útil para você! Se houver mais alguma coisa em que eu possa ajudar, por favor, me avise.
        </p>
        </div>
    </div>
  );
}

export default ProfileCard;

