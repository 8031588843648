import React, { useEffect } from 'react';
import Meuatendimento from '../services/meuatendimento'
import Contato from '../contato'
import ExpandableComponent from '../expandable'
import AtendimentoInfo from '../services/valores'
import PaymentOptions from '../services/formas_de_pagamento'


const ServicoAroma = () => {

  return (
    <div className="max-w-3xl m-auto min-h-screen py-16">
        <h1 className='text-3xl text-center md:text-4xl'>Consulta Aromaterapia Clínica</h1>

        {/* <div className="shadow-md overflow-hidden my-10 rounded-lg">
            <div className="mt-4 py-10 md:mt-0 md:mx-6 text-center">
              <div className="uppercase tracking-wide text-sm font-semibold">Aroma = odor, cheiro </div>
              <div className="uppercase tracking-wide text-sm font-semibold">Terapia = cura, tratamento </div>
            </div>
        </div> */}

        <div className='p-4'>
          <p className='mb-4'>
          A aromaterapia é uma prática ancestral que combina tradições antigas com bases científicas para criar uma terapia natural e poderosa. Esta é uma área de estudo e uma abordagem terapêutica que utiliza conhecimentos de diversas disciplinas, englobando elementos da botânica, química, farmacologia, fisiologia, agronomia, além de psicologia e neurociências.          
          </p>
          <p className='mb-4'>
          Trata-se de uma abordagem integrativa (PICs -práticas integrativas e complementares), baseada em evidências científicas e clínicas, que visa promover a saúde e o bem-estar.
          </p>
          <p className='mb-4'>
          Assim, para entender o que a Aromaterapia pode alcançar, é preciso conhecer os seus instrumentos de trabalho: os Óleos Essenciais.
          </p>
          <p className='mb-4'>
          Os óleos essenciais são compostos voláteis/semi voláteis químicos, de plantas medicinais e aromáticas, no qual tem diversas propriedades e ações para promover a saúde em níveis mentais, físicos, energéticos do ser humano, além do vasto uso em diversas outras áreas, como regeneração de solos, cosmetologia, medicamentos etc.
          </p>
          <p className='mb-4'>
          Existem diversas formas de utilizar óleos essenciais e dentro dos atendimentos focaremos em duas principais formas de administração, através do olfato (inalação) ou através do uso na pele (dérmico). 
          </p>
        </div>

        <ExpandableComponent name={"Como esse serviço pode me ajudar?"} >
          <p className='mb-4'>
          A Aromaterapia pode ajudar em vários tipos de doenças, e auxiliam no tratamento de diversas questões físicas, emocionais e mentais, tais como: 
          </p>
          <ul className='list-disc list-inside'>
            <li className="mb-2">Alívio de sintomas de estresse, Nervosismo </li>
            <li className="mb-2">Depressão e Ansiedade </li>
            <li className="mb-2">Equilíbrio hormonal, TPM, Menopausa </li>
            <li className="mb-2">Insônia </li>
            <li className="mb-2">Dores musculares, Artrite, Bursite e Artrose  </li>
            <li className="mb-2">Dor de Cabeça e Enxaqueca  </li>
            <li className="mb-2">Alergias, Sinusite, Rinite, Asma, COVID19 e Bronquite </li>
            <li className="mb-2">Falta de energia, Cansaço e Fadiga</li>
            <li className="mb-2">Concentração e Memória</li>
            <li className="mb-2">Fungos, Bactérias e Vírus </li>
            <li className="mb-2">Eczema, Rosácea e Circulação sanguínea </li>
            <li className="mb-2">Entre várias outras questões</li>
          </ul>
            
        </ExpandableComponent >
        <ExpandableComponent name={"Como funciona o atendimento?"} >
          <p className='mb-4'>
            O atendimento acontece de forma ONLINE
          </p>
          <ul className='px-4 list-disc list-inside'>
            <li className="mb-2">A consulta online acontece por <strong>chamada de vídeo pelo google meet ou zoom</strong>. Uma hora antes da nossa consulta acontecer, lhe encaminharei um link via WhatsApp.  </li>
            <li className="mb-2">A partir do momento em que você marcar um horário comigo, eu irei te enviar um <strong>link de questionário pré-consulta</strong> para você responder algumas informações importantes sobre você e queixas.  </li>
            <li className="mb-2"><strong>A primeira consulta leva em torno de 01 hora a 01:30h</strong>, neste primeiro momento vamos conversar sobre o questionário respondido. E assim, alinharemos juntas alguns pontos para dar início ao tratamento.</li>
            <li className="mb-2"><strong>Em até 7 dias úteis suas orientações serão encaminhadas pelo Whatsapp e seu Kit Aromático pronto. Assim poderemos combinar a entrega, correio ou retirada no metrô. </strong></li>
            <li className="mb-2">Após 30-40 dias faremos um <strong>retorno online</strong> para avaliarmos como foi sua adaptação às orientações sugeridas com duração de 30-40 minutos via google meet. </li>
            <li className="mb-2">Entre um atendimento e outro, sempre estarei disponível para você tirar todas suas dúvidas e compartilhar comigo suas percepções fazendo o uso do Kit Aromático, via WhatsApp! </li>
          </ul>
        </ExpandableComponent >
        <ExpandableComponent name={"O que está incluso + kit aromático"} >
          <ul className='px-4 list-disc list-inside'>
            <li className="mb-2">Consulta inicial e anamnese detalhada </li>
            <li className="mb-2">Avaliação personalizada e seleção de óleos essenciais adequados</li>
            <li className="mb-2">Recomendações e protocolos de uso (Plano Terapêutico)</li>
            <li className="mb-2">Sessões de acompanhamento para avaliação e ajustes, se necessário (Retorno Online)</li>
            <li className="mb-2"><strong>Kit Aromático: </strong>Desenvolvido e fornecido contém um inalador pessoal e uma sinergia feita com óleos essenciais e óleo vegetal de 10mL.</li>
          </ul>
        </ExpandableComponent >
        <ExpandableComponent name={"Plano de tratamento"} >
        <p className='p-4'>
        No nosso atendimento, após alinharmos as expectativas, traçaremos um plano de tratamento, sempre atendendo às suas necessidades de acordo com sua disponibilidade de tempo e também financeiro.
        </p>
        <p className='p-4'>
        Acredito que um tratamento regular pode trazer maior eficiência!
        </p>
        <p className='p-4'>
        Nossos encontros acontecem dependendo das suas necessidades e demandas, podendo ser semanalmente, a cada 15 dias, a cada 20 dias, 30 dias ou a cada 60 dias, dando assim uma constância ao tratamento. Isso será combinado pelo WhatsApp/ pós primeira consulta. 
        </p>

        <p className='p-4 font-bold'>IMPORTANTE: </p>

          <ul className="px-4 list-disc list-inside">
                <li className="mb-2">
                O Kit Aromático + materiais personalizados estão inclusos no valor da consulta.
                </li>
                <li className="mb-2">
                Os valores das entregas físicas não estão inclusos.  
                </li>
                <li className="mb-2">
                As entregas são feitas por correio ou metrô (combinar estação e dia). 
                </li>
            </ul>

        </ExpandableComponent >

        <ExpandableComponent name={"Valores"} >
        <AtendimentoInfo 
            firstAppointment={{"min": 180, "max": 200}}
            otherAppointments={{"min": 130, "max": 140}} 
          />
        </ExpandableComponent >
        <ExpandableComponent name={"Formas de pagamento"} >
          <PaymentOptions />
        </ExpandableComponent >
        

        <Contato />
    </div>
  );
};

export default ServicoAroma;
