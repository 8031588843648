import React, { useEffect } from 'react';
import Meuatendimento from '../services/meuatendimento'
import Contato from '../contato'
import ExpandableComponent from '../expandable'

import Valores from '../services/valores'
import PaymentOptions from '../services/formas_de_pagamento'


const ServicoNatu = () => {

  return (
    <div className="max-w-3xl m-auto min-h-screen py-16">
        <h1 className='text-3xl text-center md:text-4xl'>Atendimento Doula</h1>

        <div className='p-4'>
          <p className='mb-4'>
           A doula tem a função de amparar a gestante, antes, durante e no pós parto, tendo o foco de tornar a experiência do parto mais leve, segura, consciente e tranquila para a gestante e família. A doula trabalha durante toda a gestação e pós parto com suporte emocional e físico, através da presença contínua, escuta acolhedora, educação perinatal, informações baseadas em evidências e alívio de possíveis desconfortos.
          </p>
          <p className='mb-4'>
          Meu trabalho como doula vai além disso, trazendo conhecimentos da Naturologia com embasamentos das Práticas Integrativas e Complementares (PICs), unindo conhecimentos de medicinas ancestrais, que enxergam o ser humano como um todo nos diferentes níveis: mental, físico, emocional e metabólico. Enriquecendo as práticas dentro da doulagem e tornando o processo de gestação mais leve, favorecendo a manutenção de um ambiente tranquilo e acolhedor para aquela família e gestante. 
          </p>
        </div>

        <ExpandableComponent name={"Contato inicial"} >
          <p className='mb-4'>
              Esse será nosso primeiro contato entre a doula-naturóloga e a família, via whatsapp, email ou instagram. Encaminharei via pdf minha forma de trabalho, valores, formas de pagamentos, entre outros. Caso você e a família tenham interesse podemos marcar um encontro remoto via google meet para nos conhecermos melhor, esse encontro não terá nenhuma finalidade de compromisso e custo.
          </p>
        </ExpandableComponent >
        <ExpandableComponent name={"Pacotes"} >
          <p className="mt-4">
            <strong>Pacote Lua Cheia </strong>
          </p>
          <p className='mb-4'>
          O Pacote Lua Cheia é uma experiência mais estruturada. Ele foi cuidadosamente elaborado para proporcionar um acompanhamento completo durante a sua gestação. Com um cronograma pré-definido, este pacote cobre uma variedade de tópicos essenciais, explicados no pdf enviado após o primeiro contato. É ideal se você deseja uma estrutura clara e abrangente para guiá-la durante este período importante e transformador.
          </p>

          <p>
            <strong>Pacote Lua Cheia </strong>
          </p>
          <p className='mb-4'>
          Esse pacote oferece uma experiência personalizável e flexível. Aqui, temos a oportunidade de construir juntas um cronograma que se adapte às suas necessidades e ritmo. Isso significa que podemos montar uma agenda de encontros e definir o foco de cada sessão de acordo com o que você, como gestante, precisa e quer. Através deste pacote, posso lhe acompanhar de maneira dinâmica e personalizada, adaptando cada encontro à sua jornada única de maternidade.
        </p>

          
          
          
        </ExpandableComponent >
        <ExpandableComponent name={"Técnicas utilizadas"} >
           <p className="my-4">
           Essas são algumas das técnicas que podemos utilizar:
          </p> 
          <ul className="list-disc px-4">
            <li>Terapia Floral</li>
            <li>Fitoterapia</li>
            <li>Rebozo Mexicano</li>
            <li>Auriculoterapia</li>
            <li>Massagens Terapêuticas</li>
            <li>Acupuntura/Laserpuntura</li>
            <li>Técnicas de Respiração</li>
            <li>Ginecologia Natural</li>
            <li>Geoterapia</li>
            <li>Aromaterapia</li>
            <li>Ayurveda</li>
            <li>Recursos Hidroterapêuticos</li>
            <li>Sempre com um olhar atento e uma escuta ativa.</li>
          </ul>
        </ExpandableComponent >
        <ExpandableComponent name={"O que a Doula NÃO faz"} >
          <ul className="list-disc px-4">
            <li>A doula não realiza qualquer procedimento médico e/ou clínico</li>
            <li>A doula não substitui qualquer profissional que esteja envolvido na assistência ao parto. </li>
            <li>A doula não substitui o acompanhante escolhido pela parturiente, nesse caso a doula orienta o acompanhante a ter uma participação mais ativa no processo, por exemplo sugerindo formas de prestar apoio, técnicas para aliviar dor etc. </li>
            <li>A doula não questiona os procedimentos escolhidos pela equipe médica, nem discute. </li>
            <li>A doula não pode ser considerada ‘’parteira’’ , isso é restrito aos médicos e/ou parteiras tradicionais. </li>
            <li>A doula não faz exame de toque; não afere a pressão arterial; não indica remédios; não ausculta o coração do bebê; entre outros. </li>
          </ul>
        </ExpandableComponent >
        <ExpandableComponent name={"Valores"} >
           <p className="p-4">
            Para conhecer os valores do pacote e as opções de pagamento disponíveis, por favor, entre em contato pelo WhatsApp. Estou comprometida em tornar o serviço acessível e flexível, adaptando-o às necessidades individuais de cada família.
           </p>
           <p className="px-4">
              Os valores e informações são discutidos durante o contato inicial.
           </p>
        </ExpandableComponent >
      
        <Contato />
    </div>
  );
};

export default ServicoNatu;
