import { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const ExpandableComponent = ({ children, name }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full shadow-md p-4 rounded-md">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className='text-lg font-semibold'>{name}</span>
        {isOpen ? <FiChevronUp /> : <FiChevronDown />}
      </button>
      {isOpen && <div className="mt-2">{children}</div>}
    </div>
  );
};

export default ExpandableComponent;