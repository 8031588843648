import { Link } from "react-router-dom";

function ServiceCard({ image, name, description, href, price }) {
    return (
      <Link to={href}>
        <div className="flex flex-col h-96 z-0 bg-white shadow-md rounded-md overflow-hidden flex-grow w-full">
          <div
            className="relative h-64 bg-cover bg-top bg-no-repeat w-full flex-grow"
            style={{ backgroundImage: `url(${image})` }}
          >
            {/* <h3 className="absolute bottom-2 left-4 text-xl font-bold">{name}</h3> */}
          </div>
          <div className="p-4">
            <h3 className="bottom-2 left-4 text-xl font-bold flex-grow">{name}</h3>
            <p className="mb-4 text-gray-600">{description}</p>
            {/* <p className="font-semibold text-gray-800">{price}</p> */}
          </div>
        </div>
      </Link>
    );
  }
  
  export default ServiceCard;
  
