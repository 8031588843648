import React, { useEffect } from 'react';
import Meuatendimento from '../services/meuatendimento'
import Contato from '../contato'
import ExpandableComponent from '../expandable'
import PaymentOptions from '../services/formas_de_pagamento'


const ServicoNatu = () => {

  return (
    <div className="max-w-3xl m-auto min-h-screen py-16">
        <h1 className='text-3xl text-center md:text-4xl'>Consulta Arte Placentária</h1>

        <div className='p-4'>
          <p className='mb-4'>
          A arte placentária é uma prática muito popular entre as doulas, envolve usar a placenta para criar uma impressão única. Esta é expressão artística orgânica, única e significativa, que reflete a singularidade daquela "árvore da vida" que nutriu e abrigou o bebê com tanto amor e cuidado. É um gesto simbólico, repleto de carinho e respeito pelo processo incrível da vida, uma maneira lindamente artística de homenagear sua placenta e guardar essa recordação, afinal ela acompanhou o bebe durante sua gestação inteira. 
          </p>
          <p className='mb-4'>
          O carimbo de placenta envolve a criação de uma imagem impressa em papel aquarelável ou tecido. A placenta é cuidadosamente limpa e preparada para ser usada como uma matriz para o carimbo. O resultado é uma obra de arte personalizada que reflete a forma e textura da placenta, podendo ser emoldurada e apreciada como uma lembrança especial.
          </p>
        </div>

        <ExpandableComponent name={"Formas de realizar o carimbo"} >
          <p className='p-4'>Possíveis situações:</p>
          <ul className="px-4 list-disc">
            <li>Você tem sua placenta congelada: Nesse caso podemos combinar a entrega da placenta, posso descongelar, fazer o carimbo, uma arte e combinamos a devolução.
            </li>
            <li>
            Você já tem o carimbo: Já nesse caso podemos combinar a entrega, faço a arte e combinamos  a devolução. Dependendo do papel utilizado para fazer o carimbo, decidiremos juntas quais materiais serão adequados. 
            </li>
            <li>
            Caso eu seja sua doula, o carimbo está incluso independente do pacote escolhido!
            </li>
          </ul>
          <p className='p-4'>Sempre estarão inclusos 2 carimbos, um com a arte e outro somente o carimbo natural da placenta. </p>
        </ExpandableComponent >
        <ExpandableComponent name={"O que está incluso + forma de entrega "} >
          <ul className="px-4 list-disc">
            <li>Carimbo com a arte </li>
            <li>Carimbo natural da placenta. </li>
          </ul>
          <p>A forma de entrega sempre estará aberta a discussão, podendo ser feito tanto pelo correio, como retirada em metrôs de São Paulo. O valor da entrega não está incluso.
          </p>
        </ExpandableComponent >
        <ExpandableComponent name={"Valores"} >
          <p className="p-4">
            Entre em contato para mais informações! 
          </p>
          <p className="p-4">
            Ao construir valores para esse serviço, é importante considerar os materiais utilizados e o tempo dedicado. É comum que os valores sejam definidos de forma personalizada, dependendo das preferências individuais da mãe/parturiente/família.
          </p>

        </ExpandableComponent >
        <ExpandableComponent name={"Formas de pagamento"} >
         <PaymentOptions /> 
        </ExpandableComponent >
        

        <Contato />
    </div>
  );
};

export default ServicoNatu;
