import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const topics = [
  {'href': '/', 'name': 'Home'},
  {'href': '/services', 'name': 'Meus serviços'},
  {'href': '/naturologia', 'name': 'Naturologia'},
  {'href': '/sobre', 'name': 'Sobre mim'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const Navbar = () => {

  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', scroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', scroll);
    };
  }, []);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  
  function scroll() {
      const navbar = document.getElementById('navbar')
      if (window.pageYOffset >= navbar.clientHeight -30) {
          setIsScrolled(true)
      } else {
          setIsScrolled(false)
      }
  }

  return (
    <nav id="navbar" className={`w-full z-10`}>


        <Link to="/">
          <img className="w-1/2 sm:w-1/4 m-auto max-w-[200px] min-w-[120px]" src="/logo 1.png" alt="logo" />
        </Link>
        
        <div className={`w-full text-center`}>
          <button
              onClick={handleNavToggle}
              className="sm:hidden p-2 rounded-md hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition-all duration-300 ease-in-out"
            >
              <svg
                className={`${isNavOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isNavOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className={`pt-1 sm:pt-0 text-lg sm:block mx-auto max-w-7xl px-0 sm:px-6 lg:px-8 ease-in-out ${!isNavOpen ? 'hidden' : 'pb-24 md:pb-0'}`}>
            <div className="sm:flex h-16 text-center relative">
                <div className="grid px-16 bg-[#fff8f2] sm:px-0 sm:grid-cols-4 sm:gap-16 content-center m-auto font-bold">
                    {topics.map((topic) => (
                        <Link key={topic.name} onClick={() => setIsNavOpen(false)} className="inline-block my-2 border-b-2 border-transparent hover:border-amber-800 transition-transform transform-origin-center hover:scale-105" to={topic.href}>{topic.name}</Link>
                    ),)}
                </div>
            </div>
        </div>

        {isScrolled && 
          <div className="w-full text-center fixed z-10 bg-[#fff8f2] top-0 shadow-md">
            <div className="">
              <button
                  onClick={handleNavToggle}
                  className="sm:hidden p-2 rounded-md text-black hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition-all duration-300 ease-in-out"
                >
                  <svg
                    className={`${isNavOpen ? "hidden" : "block"} h-6 w-6`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className={`${isNavOpen ? "block" : "hidden"} h-6 w-6`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className={`pt-6 sm:pt-0 text-lg sm:block mx-auto max-w-7xl px-0 sm:px-6 lg:px-8 ease-in-out ${!isNavOpen ? 'hidden' : ''}`}>
                <div className="sm:flex h-16 text-center relative">
                    <div className={`grid px-16 ${isNavOpen ? 'shadow-md' : ''}  bg-[#fff8f2] sm:px-0 sm:grid-cols-4 sm:gap-16 content-center m-auto font-bold`}>
                        {topics.map((topic) => (
                            <Link key={topic.name} onClick={() => setIsNavOpen(false)} className="inline-block my-2 border-b-2 border-transparent hover:border-amber-800 transition-transform transform-origin-center hover:scale-105" to={topic.href}>{topic.name}</Link>
                        ),)}
                    </div>
                </div>
            </div>
          </div>
        }
    </nav>
  );
};
export default Navbar;